<div class="background"></div>
<div class="commands-page">
  <div class="container">
    <div class="header" id="commands">
      <h1>Avrae Commands</h1>
      <p>This page is a list of all commands, subcommands, and valid arguments that Avrae can parse.</p>
      <p>Avrae's command system is split into distinct modules. All commands are called by starting a message with the
        message prefix (default <kbd>!</kbd>, but can be configured), followed by the command name.</p>
    </div>

    <button mat-button class="module-dropdown" [matMenuTriggerFor]="moduleMenu">
      Commands by Category
      <mat-icon>expand_more</mat-icon>
    </button>

    <div class="module-container" *ngFor="let module of modules" id="{{module.name.toLowerCase()}}">
      <h2>{{module.name}}</h2>
      <p markdown class="module-description" [data]="module.desc"></p>
      <avr-command-display *ngFor="let command of module.commands" [command]="command"></avr-command-display>
    </div>
  </div>
</div>

<mat-menu #moduleMenu="matMenu">
  <button mat-menu-item *ngFor="let module of modules" (click)="scrollTo(module.name.toLowerCase())">
    {{module.name}}
  </button>
</mat-menu>
