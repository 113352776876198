<div class="ddb-footer">
  <div class="ddb-footer-wrapper-lower">
    <div class="ddb-footer-lower">
      <div class="ddb-footer-copyright">

        <div class="ddb-footer-copyright-site">
          &copy;2022 D&amp;D Beyond | All Rights Reserved
        </div>

        <div class="ddb-footer-copyright-wotc">
          Dungeons &amp; Dragons, D&amp;D, their respective logos, and all Wizards titles and characters are property of
          Wizards of the Coast LLC in the U.S.A. and other countries. &copy;2022 Wizards.
        </div>
      </div>
      <div class="ddb-footer-lower-links">
        <a href="https://company.wizards.com/en/legal/wizards-coasts-privacy-policy" class="ddb-footer-lower-links-item">Privacy Policy</a>
        <a href="https://company.wizards.com/en/legal/terms" class="ddb-footer-lower-links-item">General Terms</a>
        <a href="http://support.dndbeyond.com" class="ddb-footer-lower-links-item">Support</a>
      </div>
    </div>
  </div>
</div>
