<div class="background"></div>
<div class="landing-page">
  <div>
    <img class="ddb-logo" src="../../assets/img/DDBLogo.svg" alt="D&D Beyond Logo"/>
  </div>
  <div>
    <img class="avrae-logo" src="../../assets/img/AVRAE-FULL-LOGO-WHT.svg" alt="Avrae Logo"/>
  </div>

  <p class="mat-typography about-avrae">
    I am <b><i>Avrae</i></b>, a <b>Discord bot</b> designed to help you and your friends play D&D online.
  </p>

  <div>
    <iframe class="intro-video" src="https://www.youtube.com/embed/exm4p2HFLXc" frameborder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen></iframe>
  </div>

  <avr-feature-box
    title="Advanced Dice Roller"
    image="../../assets/img/dicedemo.png"
  >
    With a custom dice parser, Avrae is one of the most advanced dice rollers on Discord, capable of
    supporting pretty much every type of roll needed to play D&D. Advantage, disadvantage, and crits are built in, you
    can keep, drop, or reroll dice as needed, dice can explode, and dice can be bounded.
  </avr-feature-box>

  <avr-feature-box
    title="Character Sheet Integration"
    image="../../assets/img/integration.png"
    [image_left]="false"
  >
    Avrae can read character sheets from
    <a target="_blank" href="https://www.dndbeyond.com/" rel="noopener noreferrer">D&amp;D Beyond</a>,
    <a target="_blank" href="https://dicecloud.com/" rel="noopener noreferrer">Dicecloud</a>, or
    <a target="_blank" href="http://gsheet2.avrae.io" rel="noopener noreferrer">a Google Sheet</a>,
    automatically
    generating macros to roll attacks, ability checks, and saving throws. A player can then simply use a
    command to make a check, save, attack, or cast, and all necessary rolls will be resolved automatically.
  </avr-feature-box>

  <avr-feature-box
    title="Initiative Tracking"
    image="../../assets/img/initdemo.png"
  >
    The initiative tracker is a fast way to track combat in a text channel. It supports automatic combatant
    sorting, HP, AC, resistance, and status effect tracking, and integration with the character sheet
    manager and 5e content to further streamline combat.
  </avr-feature-box>

  <div class="cta-connect-avrae mat-typography" [class]="medium ? 'cta-large' : 'cta-small'">
    <h2>Connect Avrae</h2>
    <p>
      You can invite Avrae to your server here! Just select your server in the dropdown menu,
      and ensure that "Read Messages", "Send Messages", "Embed Links", and "Manage Messages" are enabled!
    </p>

    <a mat-flat-button href="http://invite.avrae.io">INVITE</a>
  </div>
</div>
