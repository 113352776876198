<div *ngIf="tome">
  <mat-toolbar color="primary" class="tome-toolbar">
    <span>
      {{tome.name}}
    </span>
    <span class="flex-spacer"></span>
    <span>
      <mat-spinner color="accent" diameter="24" *ngIf="!owner"></mat-spinner>
      <div *ngIf="owner">By {{owner.username}}</div>
    </span>
  </mat-toolbar>

  <div class="container" fxLayout="row" fxLayoutGap="8px">
    <div class="spell-list" fxFlex="grow" *ngIf="tome.spells.length">
      <mat-card class="card">

        <mat-list>
          <mat-list-item *ngFor="let spell of tome.spells">
            <div class="spell hoverable pointer" (click)="selectedSpell = spell">
              <span class="spell-name">
                {{spell.name}}
              </span>
              <span class="flex-spacer"></span>
              <span class="spell-short muted">
                {{getSpellMeta(spell)}}
              </span>
            </div>
          </mat-list-item>
        </mat-list>

      </mat-card>
    </div>

    <div class="spell-preview mat-typography mat-elevation-z3" fxFlex="grow">
      <avr-spell-embed [name]="selectedSpell?.name" [image]="selectedSpell?.image" [level]="selectedSpell?.level"
                       [school]="selectedSpell?.school" [classes]="selectedSpell?.classes"
                       [subclasses]="selectedSpell?.subclasses" [verbal]="selectedSpell?.components.verbal"
                       [somatic]="selectedSpell?.components.somatic" [material]="selectedSpell?.components.material"
                       [time]="selectedSpell?.casttime" [ritual]="selectedSpell?.ritual" [range]="selectedSpell?.range"
                       [concentration]="selectedSpell?.concentration" [duration]="selectedSpell?.duration"
                       [description]="selectedSpell?.description"
                       [higherlevels]="selectedSpell?.higherlevels"></avr-spell-embed>
    </div>
  </div>


</div>

<div *ngIf="!tome">
  <div class="container">
    <mat-card>
      <mat-spinner [diameter]="24"></mat-spinner>
      <p class="mat-error" *ngIf="error">
        {{error}}
      </p>
    </mat-card>
  </div>
</div>
