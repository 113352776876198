<div class="background"></div>
<div class="container">
  <div class="content">
    <h1>Community Links</h1>
    <p>The following are related links submitted by the Avrae community!</p>
    <p>
      Please keep in mind that the Avrae developers are not responsible for the
      maintenance of these tools.
    </p>
    <p>
      If you wish to submit a link, edit the following document and create a
      pull request. You will need to fork the repository. <br />
      <a href="{{ linkUrl }}" target="_blank">{{ linkUrl }}</a>
    </p>
    <hr />
    <markdown [src]="rawUrl"></markdown>
  </div>
</div>
