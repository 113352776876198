<mat-divider></mat-divider>

<div class="command-display" [id]="getQualifiedId()">
  <div class="click-to-expand" (click)="toggleOpen()">
    <p class="command-header">
      <code>{{command.signature}}</code>
      <mat-icon *ngIf="canBeOpened()">
        {{isOpen ? 'expand_less' : 'expand_more'}}
      </mat-icon>
    </p>
    <div class="command-closed-desc" *ngIf="!isOpen">
      <markdown>{{debrace(command.short)}}</markdown>
    </div>
  </div>

  <div class="command-desc" *ngIf="isOpen">
    <markdown>{{debrace(command.docs)}}</markdown>

<!--    <table class="command-arguments" *ngIf="command.args.length">-->
<!--      <tr>-->
<!--        <th>Arguments</th>-->
<!--      </tr>-->
<!--      <tr *ngFor="let arg of command.args">-->
<!--        <td><code>{{arg.name}}</code>{{getArgDescriptors(arg)}} - {{arg.desc}}</td>-->
<!--      </tr>-->
<!--    </table>-->
  </div>

  <div class="command-subcommands" *ngIf="command.subcommands.length">
    <h4>Subcommands</h4>
    <mat-accordion>
      <avr-command-display *ngFor="let subcommand of command.subcommands" [command]="subcommand"
                           [parentId]="getQualifiedId()"></avr-command-display>
    </mat-accordion>
  </div>
</div>
