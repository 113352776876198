<mat-card>
  <mat-card-content>
    <div class="container">
      <mat-spinner class="centered" *ngIf="working" [diameter]="36"></mat-spinner>
      <p class="mat-error" *ngIf="error">
        {{error}}
      </p>
    </div>
  </mat-card-content>
</mat-card>
